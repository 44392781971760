<template>
    <div class="create-meal-kit__wrapper" v-if="form">
        <div class="create-meal-kit__inner">
            <h2>{{ pageName }}</h2>
            <!-- Kit Type -->
            <CreationSection v-if="!isEditPage">
                <template #btn-wrapper>
                    <CRButton
                        class="btn"
                        v-for="button in mealKitButtonsData"
                        :key="button.value"
                        :active="isUnique === button.value"
                        @click="handelSelect(button.value, 'isUnique')"
                        pattern="section"
                    >
                        {{ button.title }}
                    </CRButton>
                </template>
            </CreationSection>

            <LangButtons class="lang-buttons" :locale="locale" @selectLocale="selectLocale" />

            <!-- Menu type -->
            <CreationSection v-if="!isUnique" :title="$t('mealKits.selectMenus')">
                <template #btn-wrapper>
                    <CRButton
                        class="btn"
                        v-for="menuType in menuTypes"
                        :key="menuType.id"
                        :active="form.selectedMenuTypesIds.includes(menuType.id)"
                        @click="handelToggle(menuType.id, 'selectedMenuTypesIds')"
                        pattern="toggle"
                    >
                        {{ $t(`common.${menuType.type}`) }}
                    </CRButton>
                </template>
            </CreationSection>

            <!-- Common Inputs -->
            <CRInput
                class="input"
                v-model="form.name[locale]"
                type="text"
                :label="$t('common.kitName')"
                :placeholder="$t('placeholder.enterKitName')"
                required
                :errors="errors.name"
                @onChange="$filters.clearError(errors, 'name')"
            />

            <Wysiwyg
                class="wysiwyg"
                v-model="form.description[locale]"
                :key="componentKey"
                :placeholder="$t('placeholder.enterKitDescription')"
                contentType="html"
                theme="snow"
                toolbar="essential"
            />

            <CRInput
                class="input price-input"
                v-if="isUnique"
                v-model="form.price"
                type="number"
                :label="$t('common.price')"
                :placeholder="$t('placeholder.enterPrice')"
                required
                :errors="errors.price"
                @onChange="$filters.clearError(errors, 'price')"
            />

            <!-- Meals -->
            <CreationSection v-if="!isUnique" :title="$t('mealKits.selectMeals')">
                <template #btn-wrapper v-if="meals.length">
                    <CRButton
                        class="btn"
                        v-for="meal in meals"
                        :key="meal.id"
                        :active="form.selectedMealsTypes.includes(meal.type)"
                        @click="handelToggleMealType(meal)"
                        pattern="toggle"
                    >
                        {{ $t(`common.${meal.type}`) }}
                    </CRButton>
                </template>
            </CreationSection>

            <!-- Combinations -->
            <CreationSection v-if="!isUnique" :title="$t('mealKits.combinations')">
                <template #title-btn>
                    <CRButton plus @click="isCreateCombinationModalOpened = true" />
                </template>
                <template #btn-wrapper v-if="filteredCombinations.length">
                    <p class="mb-10">Kombinacijas prašome trinti atsakingai, nes šios kombinacijos gali būti priskirtos prie kito rinkinio.</p>
                    <div class="combinations">
                        <div class="combinations__item" v-for="combination in filteredCombinations" :key="combination.id">
                            <CRButton
                                class="btn"
                                :key="combination.id"
                                :active="form.selectedCombinationsIds.includes(combination.id)"
                                @click="handelToggle(combination.id, 'selectedCombinationsIds')"
                                pattern="toggle"
                            >
                                {{ $filters.getTranslationByKey(combination.name) }}
                            </CRButton>
                            <CRButton  class="btn" color="danger" size="xx-small" @click="deleteCombination(combination.id)">Pašalinti</CRButton>
                        </div>
                    </div>
                </template>
                <div v-if="!form.selectedMealsTypes.length">
                    <p>{{ $t('mealKits.selectMinimumMeals') }}</p>
                </div>
                <div v-if="form.selectedMealsTypes.length && !filteredCombinations.length">
                    <p>{{ $t('mealKits.noCombinations') }}</p>
                </div>
                <CreateCombinationModal
                    v-if="isCreateCombinationModalOpened"
                    :meals="meals"
                    @newCombination="addNewCombination"
                    @close="isCreateCombinationModalOpened = false"
                />
            </CreationSection>

            <!-- Periods-->
            <CreationSection v-if="!isUnique" :title="$t('mealKits.selectPeriods')">
                <template #btn-wrapper v-if="periods.length">
                    <CRButton
                        class="btn"
                        v-for="period in periods"
                        :key="period.id"
                        :active="checkIfPeriodObjectAddedInPeriodArray(period)"
                        @click="handelPeriodToggle(period)"
                        pattern="toggle"
                    >
                        {{ $filters.getTranslationByKey(period.name) }}
                    </CRButton>
                </template>
            </CreationSection>

            <!-- Calories -->
            <CreationSection v-if="!isUnique" :title="$t('mealKits.calorieNorms')">
                <div v-if="form.selectedPeriods.length">
                    <div class="tag__wrapper" v-if="form.calories.length">
                        <CRTag
                            class="mr-8 mb-8"
                            v-for="(calorie, index) in form.calories"
                            :key="index"
                            type="additional"
                            delete-allowed
                            svg-color="#00595A"
                            @delete="removeItemByIndex(index, 'calories', calorie.amount)"
                        >
                            {{ calorie.amount }}kcal
                        </CRTag>
                    </div>
                    <CRInput
                        class="input"
                        v-model.number="calorieNorms.amount"
                        type="number"
                        :label="$t('common.calorieNorm')"
                        :placeholder="$t('placeholder.enterCalorie')"
                        required
                        :errors="calorieNormsErrors?.amount"
                        @onChange="$filters.clearError(calorieNormsErrors, 'amount')"
                        :max="100000"
                    />
                    <CRButton @click="addData('calorieNorms', 'calorieNormsErrors', 'calories')">
                        {{ $t('buttons.add') }}
                    </CRButton>
                </div>
                <div v-else>
                    <p>Select minimum 1 period</p>
                </div>
            </CreationSection>

            <div v-if="!isUnique">
                <CreationSection
                    v-for="(item, index) in Object.entries(form.caloriesPeriodsPrices)"
                    :title="`${item[0]}${$t('mealKits.kcalPricing')}`"
                    :key="index"
                >
                    <div class="tag__wrapper" v-if="form.calories.length">
                        <div v-for="(elem, index) in item[1]" :key="index">
                            <CRTag v-if="elem.price" class="mr-8 mb-8" type="additional" svg-color="#00595A">
                                {{ $filters.getTranslationByKey(elem.period.name) }} -
                                {{ elem.price * elem.period.amount }}€
                            </CRTag>
                        </div>
                    </div>
                    <CRInput
                        class="input"
                        v-for="input in item[1]"
                        :key="input.period.id"
                        v-model="input.price"
                        type="number"
                        :label="`${input.period.amount} ${$t('mealKits.dayDaysPrice')}`"
                        :placeholder="`${$t('mealKits.enter')} ${input.period.amount} ${$t('mealKits.dayDaysPrice')}`"
                        required
                    />

                    <h3>{{ $t('mealKits.averageDailyMacronutrients') }}</h3>
                    <CRInput
                        class="input"
                        type="number"
                        :label="$t('common.carbohydrates')"
                        :placeholder="$t('common.carbohydrates')"
                        v-model.number="form.calories[index].averageCarbohydrates"
                        :max="100000"
                    />
                    <CRInput
                        class="input"
                        type="number"
                        :label="$t('common.fats')"
                        :placeholder="$t('common.fats')"
                        v-model.number="form.calories[index].averageFats"
                        :max="100000"
                    />
                    <CRInput
                        class="input"
                        type="number"
                        :label="$t('common.proteins')"
                        :placeholder="$t('common.proteins')"
                        v-model.number="form.calories[index].averageProteins"
                        :max="100000"
                    />
                </CreationSection>
            </div>

            <!-- Image gallery -->
            <ImagesUploadSection
                :title="$t('mealKits.imageGallery')"
                :image-files="form.imageFiles"
                @makeThumbnail="makeThumbnail"
                @removePicture="removePicture"
                @handleUpload="handleUpload"
            />

            <!-- Order details -->
            <CreationSection v-if="isUnique" :title="$t('mealKits.orderDetails')">
                <CRInput
                    class="input"
                    v-for="input in deliveryInputsData"
                    :key="input.id"
                    v-model="form[input.value]"
                    type="date"
                    :label="input.label"
                    required
                    :errors="errors[input.errors]"
                    @onChange="$filters.clearError(errors, input.errors)"
                />
                <div class="tag__wrapper" v-if="form.deliveryTimes.length">
                    <CRTag
                        class="mr-8 mb-8"
                        v-for="(deliveryTime, index) in form.deliveryTimes"
                        :key="index"
                        type="additional"
                        delete-allowed
                        svg-color="#00595A"
                        @delete="removeItemByIndex(index, 'deliveryTimes')"
                    >
                        {{ getLabelForDeliveryTime(deliveryTime) }}
                    </CRTag>
                </div>
                <CRInput
                    class="input"
                    v-for="input in deliveryTimesInputsData"
                    :key="input.id"
                    v-model="orderTimeData[input.value]"
                    type="time"
                    :label="input.label"
                    required
                    :errors="orderTimeDataErrors[input.errors]"
                    @onChange="$filters.clearError(orderTimeDataErrors, input.errors)"
                />
                <CRCheckbox
                    class="input"
                    :checked="orderTimeData.deliverDayBefore"
                    :value="orderTimeData.deliverDayBefore"
                    v-model="orderTimeData.deliverDayBefore"
                    @change="orderTimeData.deliverDayBefore = !orderTimeData.deliverDayBefore"
                >
                    {{ $t('otherSettings.deliveryDayBefore') }}
                </CRCheckbox>
                <CRButton @click="addData('orderTimeData', 'orderTimeDataErrors', 'deliveryTimes')">
                    {{ $t('buttons.addOrderTime') }}
                </CRButton>
            </CreationSection>

            <!-- Discount -->
            <CreationSection :title="$t('mealKits.applyDiscount')">
                <template #btn-wrapper>
                    <CRButton
                        class="btn"
                        v-for="discount in discounts"
                        :key="discount.id"
                        :active="form.selectedDiscountId === discount.id"
                        @click="handelSelect(discount.id, 'selectedDiscountId')"
                        pattern="section"
                    >
                        {{ discount.amount }}{{ getSign(discount.type) }}
                    </CRButton>
                </template>
            </CreationSection>
        </div>

        <!-- Unique menu -->
        <CreationSection v-if="isUnique" :title="$t('mealKits.uniqueMenu')" class="unique-menu__wrapper">
            <CRButton class="btn" @click="addNewUniqueMenu">
                {{ $t('buttons.addNewItem') }}
            </CRButton>
            <div class="menus__wrapper" :class="{ empty: !form.uniqueMenus.length }">
                <h4 v-if="!form.uniqueMenus.length">
                    {{ $t('mealKits.noItemsAddedToMenu') }}
                </h4>
                <DishCard
                    v-else
                    class="card"
                    v-for="(menu, index) in form.uniqueMenus"
                    :key="index"
                    :dish="menu.dish"
                    :menu="menu"
                    dish-menu-view
                    @select="onDishSelectHandler"
                >
                    <template #editable-title="{ isEditMenuNameOpened, clicked }">
                        <div class="editable-title__wrapper">
                            <CRInput
                                v-if="isEditMenuNameOpened"
                                class="input menu-name"
                                v-model="menu.name[locale]"
                                :placeholder="$t('placeholder.enterMenuName')"
                                :label="$t('common.menuName')"
                                @blur="clicked"
                                required
                            />
                            <h3 v-else>{{ menu.name[locale] }}</h3>
                            <div class="f-align-center">
                                <CRButton
                                    v-if="!isEditMenuNameOpened"
                                    minus
                                    @click="removeItemByIndex(index, 'uniqueMenus')"
                                    class="mr-10"
                                />
                                <CRButton :edit="!isEditMenuNameOpened" :plus="isEditMenuNameOpened" @click="clicked" />
                            </div>
                        </div>
                    </template>
                </DishCard>
            </div>
        </CreationSection>

        <div class="create-meal-kit__inner">
            <!-- Show/hide -->
            <CreationSection :title="$t('mealKits.showHideAfterCreating')">
                <template #btn-wrapper>
                    <CRButton
                        class="btn"
                        v-for="button in hideMealKitButtonsData"
                        :key="button.value"
                        :active="form.isHidden === button.value"
                        @click="handelSelect(button.value, 'isHidden')"
                        pattern="section"
                    >
                        {{ button.title }}
                    </CRButton>
                </template>
            </CreationSection>
        </div>
        <RequiredText class="required-text" />

        <CRButton @click="createMealKit" :disabled="createBtn.isDisable" :loading="createBtn.isLoading">
            {{ isEditPage ? $t('buttons.update') : $t('buttons.create') }}
        </CRButton>
    </div>
</template>

<script>
    import Wysiwyg from '@/components/Common/Wysiwyg/Wysiwyg.vue';
    import ImagesUploadSection from '@/components/Common/ImagesUploadSection/ImagesUploadSection.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRCheckbox from '@/components/Common/Checkbox/CRCheckbox';
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import RequiredText from '@/components/Common/Tags/RequiredText.vue';
    import DishCard from '@/components/Admin/Dishes/DishCard.vue';
    import CreateCombinationModal from '@/components/Modals/CreateCombinationModal.vue';
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import { mealKitButtonsData, hideMealKitButtonsData, deliveryInputsData } from '@/config/mealKits/mealKits';
    import { CombinationApi, MealApi, MenuTypeApi, PeriodApi, DiscountApi, MealKitApi, DishApi } from '@/api';
    import { getSign } from '@/helpers/SignHelper';
    import { validateForm } from '@/helpers/ValidationRulesHelper';
    import { getLabelForDeliveryTime } from '@/helpers/DeliveryDateHelper';
    import {
        prepareInitialDataForPage,
        clearForm,
        prepareDataForRequest,
        prepareMealKitFormDataValidation,
        uploadMealKitImage,
        deleteMealKitImage,
        changeMealKitThumbnailImage,
        getFilteredCombinations,
    } from '@/helpers/MealKitHelper';
    import { deliveryTimesInputsData } from '@/config/deliveryTimes/deliveryTimes';
    import { handleUploadImage } from '@/helpers/UploadImageHelper';
    import { mapGetters } from 'vuex';
    import { langMixin, rerenderMixin } from '@/utils/mixins';

    const CALORIE_NORM_STATE = {
        amount: '',
    };

    const CALORIE_AVERAGE_MACRONUTRIENT_STATE = {
        averageCarbohydrates: null,
        averageFats: null,
        averageProteins: null,
    };

    const ORDER_TIME_STATE = {
        startTime: '',
        endTime: '',
        deliverDayBefore: false,
    };

    const UNIQUE_MENU_STATE = {
        id: 0,
        name: {
            en: 'Meal name',
            lt: 'Maisto pavadinimas',
        },
        dish: null,
        isUnique: true,
    };

    export default {
        name: 'CreateMealKit',
        components: {
            CRButton,
            CRInput,
            CRCheckbox,
            CRTag,
            RequiredText,
            DishCard,
            CreateCombinationModal,
            CreationSection,
            ImagesUploadSection,
            Wysiwyg,
        },
        mixins: [langMixin, rerenderMixin],
        data() {
            return {
                // imported helpers and configs
                getLabelForDeliveryTime,
                getSign,
                mealKitButtonsData,
                hideMealKitButtonsData,
                deliveryTimesInputsData,
                deliveryInputsData,

                // fetched states
                meals: [],
                menuTypes: [],
                filteredCombinations: [],
                combinations: [],
                periods: [],
                discounts: [],

                calorieNorms: { ...CALORIE_NORM_STATE },
                calorieNormsErrors: {},

                orderTimeData: { ...ORDER_TIME_STATE },
                orderTimeDataErrors: {},

                uniqueMenuData: { ...UNIQUE_MENU_STATE },

                routerName: '',
                isCreateCombinationModalOpened: false,
                createBtn: {
                    isLoading: false,
                    isDisable: true,
                },

                // data for creation
                isUnique: false,
                form: null,
                // errors data for creation
                errors: {},
            };
        },

        computed: {
            ...mapGetters('mealKit', ['currentMealKit']),
            isEditPage() {
                return this.routerName === 'updateMealKit';
            },
            pageName() {
                return this.isEditPage ? this.$t('mealKits.editKit') : this.$t('mealKits.addNewKit');
            },
        },

        watch: {
            locale() {
                this.componentKey += 1;
            },

            form: {
                handler() {
                    this.createBtn.isDisable = !validateForm(prepareMealKitFormDataValidation(this.form, this.isUnique))
                        .isValid;
                },
                deep: true,
                immediate: true,
            },
            $route: {
                async handler(value) {
                    try {
                        this.routerName = value.name;
                        const response = await prepareInitialDataForPage(value.name, this.currentMealKit);

                        this.isUnique = response.isUnique;
                        this.form = response.form;

                        this.filteredCombinations = getFilteredCombinations(
                            this.combinations,
                            this.form.selectedMealsTypes
                        );
                    } catch (error) {
                        this.$filters.toast(error.message);
                    }
                },
                immediate: true,
            },
            async 'combinations.length'() {
                this.filteredCombinations = getFilteredCombinations(this.combinations, this.form?.selectedMealsTypes);
            },
        },

        methods: {
            // Meal kit creation
            async createMealKit() {
                try {
                    this.createBtn.isLoading = true;

                    if (this.isEditPage) {
                        await MealKitApi.update(
                            prepareDataForRequest(this.form, this.isUnique, this.routerName),
                            this.$route.params.meal_kit_id
                        );
                    } else {
                        await MealKitApi.store(prepareDataForRequest(this.form, this.isUnique, this.routerName));
                    }
                    this.$router.push({ name: 'allAdminMealKits' });
                } catch (error) {
                    this.errors = error.errors;
                    Object.entries(error.errors).forEach((element) => {
                        this.$filters.toast(element[1][0]);
                    });
                } finally {
                    this.createBtn.isLoading = false;
                }
            },

            // Common select handlers
            handelSelect(value, field) {
                if (field === 'isUnique') {
                    this[field] = value;
                    this.form = clearForm(this.form);
                } else {
                    this.form[field] = value;
                }
            },

            handelToggle(value, field) {
                if (this.form[field].includes(value)) {
                    this.form[field] = this.form[field].filter((itemId) => itemId !== value);
                } else {
                    this.form[field].push(value);
                }
            },

            async deleteCombination(id) {
                try {
                    await CombinationApi.destroy(id);
                    this.filteredCombinations = this.filteredCombinations.filter((combination) => combination.id !== id);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            checkIfPeriodObjectAddedInPeriodArray(item) {
                return this.form.selectedPeriods.some((elem) => elem.id === item.id);
            },

            async removeItemByIndex(value, field, id) {
                try {
                    this.form[field] = this.form[field].filter((item, index) => index !== value);

                    if (field === 'imageFiles' && this.isEditPage) {
                        await deleteMealKitImage(id);
                    }

                    if (field === 'calories') {
                        Object.entries(this.form.caloriesPeriodsPrices).forEach(() => {
                            delete this.form.caloriesPeriodsPrices[id];
                        });
                    }
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            // select Meal Type
            handelToggleMealType(value) {
                this.handelToggle(value.id, 'selectedMealsIds');
                this.handelToggle(value.type, 'selectedMealsTypes');

                this.filteredCombinations = getFilteredCombinations(this.combinations, this.form.selectedMealsTypes);

                this.form.selectedCombinationsIds = [];
            },

            // Combinations
            addNewCombination(combination) {
                this.combinations.push(combination);
            },

            // Images
            async handleUpload(files) {
                try {
                    handleUploadImage(files, this.form.imageFiles);

                    if (this.isEditPage) {
                        this.form.imageFiles = await uploadMealKitImage(files, this.$route.params.meal_kit_id);
                    }
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async makeThumbnail({ index, id }) {
                try {
                    this.form.imageFiles[0] = this.form.imageFiles.splice(index, 1, this.form.imageFiles[0])[0];

                    if (this.isEditPage) {
                        await changeMealKitThumbnailImage(id);
                    }
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async removePicture({ value, id }) {
                this.removeItemByIndex(value, 'imageFiles', id);
            },

            // OrderTime add
            // Calories add
            addData(state, errorsState, formState) {
                const validateFormData = validateForm(this[state], this[errorsState]);
                this[errorsState] = validateFormData.errorState;

                if (validateFormData.isValid) {
                    this.form[formState].push(this[state]);

                    if (state === 'calorieNorms') {
                        const caloriesArray = this.form[formState];

                        caloriesArray[caloriesArray.length - 1] = {
                            ...caloriesArray[caloriesArray.length - 1],
                            ...CALORIE_AVERAGE_MACRONUTRIENT_STATE,
                        };

                        this.form.caloriesPeriodsPrices[this[state].amount] = [];
                        this.form.selectedPeriods.forEach((period) => {
                            this.form.caloriesPeriodsPrices[this[state].amount].push({ period, price: '' });
                        });
                    }

                    this[state] = state === 'orderTimeData' ? { ...ORDER_TIME_STATE } : { ...CALORIE_NORM_STATE };
                }
            },

            // Period
            handelPeriodToggle(period) {
                if (this.checkIfPeriodObjectAddedInPeriodArray(period)) {
                    this.form.selectedPeriods = this.form.selectedPeriods.filter((elem) => elem.id !== period.id);

                    Object.entries(this.form.caloriesPeriodsPrices).forEach((element) => {
                        this.form.caloriesPeriodsPrices[element[0]] = element[1].filter(
                            (elem) => elem.period.id != period.id
                        );
                    });

                    if (!this.form.selectedPeriods.length) {
                        this.form.calories = [];
                    }
                } else {
                    this.form.selectedPeriods.push(period);

                    Object.entries(this.form.caloriesPeriodsPrices).forEach((element) => {
                        this.form.caloriesPeriodsPrices[element[0]].push({ period, price: '' });
                    });
                }
            },

            // Unique Menu
            addNewUniqueMenu() {
                this.uniqueMenuData.id = this.form.uniqueMenus.length;
                this.form.uniqueMenus.push(this.uniqueMenuData);

                this.uniqueMenuData = { ...UNIQUE_MENU_STATE };
            },

            async onDishSelectHandler(data) {
                try {
                    const response = await DishApi.show(data.dishId);

                    const menu = this.form.uniqueMenus.find((item) => item.id === data.menuId);
                    menu.dish = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            // Get data
            async getDataForSelections(Api, field) {
                try {
                    const response = await Api.getAll();
                    this[field] = response.data;

                    if (field === 'discounts') {
                        this[field].unshift({
                            id: null,
                            amount: this.$t('common.none'),
                            type: '',
                        });
                    }
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        beforeRouteLeave(to, from, next) {
            this.form = clearForm(this.form);
            next();
        },

        async created() {
            await this.getDataForSelections(CombinationApi, 'combinations');
            await this.getDataForSelections(MealApi, 'meals');
            await this.getDataForSelections(MenuTypeApi, 'menuTypes');
            await this.getDataForSelections(PeriodApi, 'periods');
            await this.getDataForSelections(DiscountApi, 'discounts');
        },
    };
</script>

<style lang="scss" scoped>
    .combinations {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        
        .btn {
            width: 100%;
        }

        &__item {
            border: solid 1px #ddd;
            padding: 5px;
            border-radius: 10px;
            flex-basis: 30%;
        }
    }
    .create-meal-kit__wrapper {
        padding-bottom: 30px;

        h2 {
            margin: 20px 0;
        }

        .input {
            margin-top: 20px;
            margin-bottom: 20px;

            &.price-input {
                margin-top: 20px;
            }
        }

        .tag__wrapper {
            @include row-align-center;
            flex-wrap: wrap;

            margin-bottom: 30px;
        }

        .required-text {
            border-bottom: 1px solid $grey-form;
            padding-bottom: 30px;
            margin-bottom: 20px;
        }

        .unique-menu__wrapper {
            padding: 10px 20px;
            box-shadow: $box-shadow-dark;
            border-radius: $br-20;

            .menus__wrapper {
                @include row-align-start-justify-between;
                flex-wrap: wrap;

                min-height: 130px;

                &.empty {
                    @include row-align-center-justify-center;
                }

                h4 {
                    color: $grey-disabled;
                    text-align: center;
                }

                .card {
                    width: 100%;
                    margin-top: 15px;

                    .editable-title__wrapper {
                        @include row-align-center-justify-between;

                        margin-bottom: 10px;

                        h3 {
                            max-width: 60%;
                            margin: 0;

                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .menu-name {
                            margin-bottom: 0;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    @include media($md) {
        .create-meal-kit__wrapper .unique-menu__wrapper .menus__wrapper .card {
            width: 48%;
            margin-top: 15px;
        }
    }

    @include media($lg) {
        .create-meal-kit__wrapper {
            padding-bottom: 60px;

            .create-meal-kit__inner {
                max-width: 700px;
            }

            h2 {
                font-size: $font-34;
            }

            .required-text {
                padding-bottom: 40px;
                margin-bottom: 30px;
            }
        }
    }

    @include media($xl) {
        .create-meal-kit__wrapper .unique-menu__wrapper .menus__wrapper {
            @include row-align-start-justify-start;

            .card {
                width: 32%;
                margin-top: 15px;
                margin-right: 2%;

                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        }
    }
</style>
